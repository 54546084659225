<template>
    <div class="hero-view-more" ref="my-hero">
        <header class="header contenedor">
            <div class="logo">
                <img :src="require('@/assets/images/logo/logo-the-fives.png')" alt="">  
            </div>
            <nav class="navegacion">                
                <router-link :to="{ name: 'sales' }" class="mr-1">Tours</router-link>

                <cart-dropdown/>                                                     
            </nav>
        </header>
        <div class="contenido-hero contenedor">
            <h1 v-if="categorySelected">{{categorySelected.name}}</h1>            
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CartDropdown from '@/modules/shop/components/cart/CartDropdown'

export default {
    components:{    
        CartDropdown
    },
    computed:{
        ...mapState('shop', ['categorySelected']),
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landing.scss";
</style>