<template>
    <div>    
        <HeroViewMore/>
        <b-container class="mb-4 mt-2">        
           <b-row>                
                <b-col md="3">
                    <b-card>
                        <div class="d-flex justify-content-between">
                            <div>
                                <b>Filtros</b>
                            </div>
                            <div
                                :class="{'collapsed': !visible}"
                                style="cursor:pointer"
                                :aria-expanded="visible ? 'true' : 'false'"
                                :aria-controls="collapseItemID"
                                role="tab"
                                data-toggle="collapse"
                                @click="updateVisible(!visible)"
                            >                                 
                                <img :src="require('@/assets/images/icons/filter.png')" alt="filtro" height="26px">                                 
                            </div>
                        </div>  
                        <hr v-if="visible">                      
                        <b-collapse :id="collapseItemID" v-model="visible" :accordion="accordion" role="tabpanel" >
                            <b-card-body>
                                <b-form-radio-group
                                    name="cats"
                                    class="categories-radio-group"
                                    stacked                                                                                     
                                    :options="categories" 
                                      v-model="categorySelected.id"
                                />              
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-col>            
                <b-col md="9">
                    <b-card no-body style="max-width: 100%; height: 200px;" v-for="(product, index) in bestSellers" :key="index">
                        <b-row >
                            <div class="col-xs-4 col-md-4  ">
                            <b-card-img
        style="height:200px;"        
        :alt="`${product.name}-${product.id}`"
        :src="imgUrl + product.coverImage"         />                
                            </div>
                            <div class="col-xs-8 col-md-8 "  @click="productClick(product, index)">
                                <b-card-body>
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus at in odio recusandae animi possimus, aspernatur, nesciunt dolorum quia quae harum ea tempore libero temporibus ut officia et ipsum eveniet!
                                </b-card-body>
                            </div>
                          
                        </b-row>
                        </b-card>
                </b-col>            

           </b-row>                   
        </b-container>
        <Footer/>    
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import HeroViewMore from '@/modules/shop/components/home/HeroViewMore'
import BestSellers from '@/modules/shop/components/home/BestSellers'
import FlagshipProduct from '@/modules/shop/components/home/FlagshipProduct'
import CategoryProducts from '@/modules/shop/components/home/CategoryProducts'
import Footer from '@/modules/shop/components/home/Footer'
import {  overWriteAxiosHeaders } from '@/helpers/helpers'

export default {
    components:{
        HeroViewMore,
        BestSellers,
        FlagshipProduct,
        CategoryProducts,
        Footer,        
    },
    async created(){
        const queryParams = { token: process.env.VUE_APP_API_TOKEN }
        overWriteAxiosHeaders( queryParams )                     
    },
    mounted(){
        this.initCarousel()
    },
    data(){
        return {
            visible: true,
            collapseItemID: '',
            openOnHover: this.$parent.hover,
            imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
        }
    },
    computed:{
        ...mapState('products',[ 'categoriesHome','bestSellers']),
        ...mapState('shop',[ 'categorySelected']),

        accordion() {
            return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
        },
        categories(){
            const categories = []
            this.categoriesHome.forEach(category => {
                categories.push({
                    action:'get-products-by-category',
                    text: category.name,
                    value: category.id,
                })
            })
            return categories
        }
    },
    methods:{
        ...mapActions('products',['getInitialContentLanding']),
        ...mapMutations('products',[ 'setBestSellersProducts','setCategoriesHome']),
        initCarousel(){   
        //obtener la imagen
            const imagenHero = document.querySelector('.hero-view-more')
            //inicializar las variables
            let i = 0
            let tiempo = 0
            // arreglo de imagenes de fondo 
            const imagenes = [ require('@/assets/images/landing/arriba2.jpg'), require('@/assets/images/landing/arriba.jpg'), require('@/assets/images/landing/lake.jpg')]

            setInterval( ()=> {
                imagenHero.style.backgroundPositionY= '-'+tiempo+'px';
                if(tiempo > 80){
                    tiempo = 0
                    imagenHero.style.backgroundImage = "url("+imagenes[i]+")"

                    if(i === imagenes.length - 1){
                        i = 0
                    }else{
                        i++
                    }
                }
                tiempo++
            },100)       
        },
        updateVisible(val = true) {
            this.visible = val
            this.$emit('visible', val)
        },
        collapseOpen() {
            if (this.openOnHover) this.updateVisible(true)
        },
        collapseClose() {
        if (this.openOnHover) this.updateVisible(false)
        },       
        }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/landing.scss";

.categories-radio-group{
    ::v-deep > .custom-control {
    margin-block-end: 0.75rem;
  }
}
</style>